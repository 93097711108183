<template>
  <ag-grid-vue
    ref="agGridTable"
    :components="cellRendererComponents"
    class="ag-theme-material w-100 my-4 ag-grid-table order-payment-grid"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :rowData="paymentItems"
    :gridOptions="gridOptions"
    rowSelection="single"
    :pagination="false"
    :suppressPaginationPanel="true"
  >
  </ag-grid-vue>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererPaymentNotes from './cell-renderers/CellRendererPaymentNotes.vue'

import Vue from "vue"

export default {
  components: {
    AgGridVue,
    CellRendererPaymentNotes
  },
  props: { 
    paymentItems: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererPaymentNotes
      },
      searchQuery: '',
      showDeleteRolePopup: false,
      selectedReplacementRole: {}
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
  },
  created () {
   
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'paymentId', hide: true },
      { field: 'companyId', hide: true },
      { headerName: 'Payment Provider', field: 'paymentSupplierName',  width: 200, suppressSizeToFit: false, },
      { headerName: 'Payment Method', field: 'paymentMethodName',  width: 200, suppressSizeToFit: false, },
      { 
        headerName: 'Amount', 
        field: 'paymentAmount',  
        width: 150, 
        suppressSizeToFit: true,
        valueGetter: params => params.data.paymentAmount + ' ' + params.data.paymentCurrency
      },
      { headerName: 'Reference', field: 'paymentReference',  width: 150, suppressSizeToFit: false, },
      { headerName: '', sortable: true, suppressSizeToFit: true, width: 60, cellRendererFramework: Vue.extend(CellRendererPaymentNotes) },
      { headerName: 'Date/Time', field: 'paymentCreated',  width: 150, suppressSizeToFit: false, },


    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
      autoHeight: true,
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridReady(params) {

    },
  }
}
</script>

<style scoped>
.order-payment-grid {
  height: 200px;
}
</style>