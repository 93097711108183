<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-1/3">
        <h5>Extra</h5>
      </div>
     
    </div>
    <div class="vx-row">
      <div class="vx-col w-1/3">
        <travelify-display-field label="Group Type" :value='itemdata.Type' />
        <travelify-display-field label="Group Name" :value='itemdata.Name' />
      </div>
      <div class="vx-col w-2/3">
        <h6>Description:</h6>
        <p class="mt-2" v-html="itemdata.Description"></p>
      </div>
    
    </div>

    <h5 class="mt-5">Extras:</h5>
    <div v-for="(extra, index) in itemdata.Extras" :key="index">
      <vs-divider class="mb-3 mt-2" />
      <extra-item-extras :extra="extra"/>
    </div>
   
  </div>
</template>

<script>

import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import InfoItems from './InfoItems.vue';
import ExtraItemExtras from './ExtraItemExtras.vue';

export default {
  components: {
    TravelifyDisplayField,
    ExtraItemExtras,
    InfoItems
  },
  props: {
    ExtraItemExtratem: { type: Object, required: true },
    itemdata: { type: Object, required: true },
    orderdata: { type: Object, required: true },
  },
  computed: {
   
  },
  
};
</script>

<style>

</style>