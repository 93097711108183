<template>
  <travio-modal v-show="showEditOrderItemDialog" width='660' height="350" zIndex="999">
    <h3 slot="header">Edit Order Item Data</h3>
    <div slot="body">
      <vs-alert v-if="editableOrderItemData.errorMessage" color="danger" active="true">
        Error: {{editableOrderItemData.errorMessage}}
      </vs-alert>
      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Type</span>
        </div>
        <div class="vx-col w-3/4">
          <span>{{ editableOrderItemData.productName }}</span>
        </div>
      </div>
      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Supplier</span>
        </div>
        <div class="vx-col w-3/4">
          <span>{{ editableOrderItemData.supplierName }}</span>
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Booking Reference</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderItemData.bookingReference" v-validate="'max:50'" name="Booking Reference" />
          <span class="text-danger text-sm">{{ errors.first('Booking Reference') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Supplier Price</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input type="number" class="w-full" v-model.number="editableOrderItemData.supplierPrice"  />
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Supplier Price Currency</span>
        </div>
        <div class="vx-col w-3/4">
          <v-select
            v-model="editableOrderItemData.supplierPriceCurrency"
            name="Order Item Status"
            :reduce="(x) => x.code"
            :clearable="false"
            append-to-body
            :options="currencyOptions"
          />
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Selling Price</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input type="number" class="w-full" v-model.number="editableOrderItemData.sellingPrice"  />
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Status</span>
        </div>
        <div class="vx-col w-3/4">
          <v-select
            v-model="editableOrderItemData.statusId"
            name="Order Item Status"
            :reduce="(x) => x.code"
            :clearable="false"
            append-to-body
            :options="orderItemStatusOptions"
          />
        </div>
      </div>
      
    
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button class="" color="primary" @click="saveEditOrderItemData" type="filled">Save</vs-button>      
      <vs-button class="ml-2" color="danger" @click="close" type="filled">Cancel</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  data () {
    return {
      showEditOrderItemDialog: false,
      editableOrderItemData: {},
      orderItemStatusOptions: [
        { code: 1, label: 'Unconfirmed' }, 
        { code: 2, label: 'Processing'},
        { code: 3, label: 'On Hold'},
        { code: 4, label: 'Confirmed' },
        { code: 5, label: 'Confirmed Failure'},
        { code: 6, label: 'Cancelled'},       
      ],
    }
  },
  props: {
    applicationId: { required: true }, 
    orderId: { required: true }, 
    itemId: { required: true },
    productList: { required: true},
    currencyOptions: { required: true }
  },
  mounted (){
    this.launchEditOrderItemDialog();
  },
  methods: {
    launchEditOrderItemDialog() {
      const currentItem = this.productList.find(x => x.orderItemId == this.itemId)
      const sellingPriceComponents = currentItem && currentItem.itemSellingPrice.split(' ') || [0, '']
      const supplierPriceComponents = currentItem && currentItem.itemSupplierPrice.split(' ') || [0, '']
      this.editableOrderItemData = {
        productName: currentItem.productName, // read only
        supplierName: currentItem.supplierName, // read only
        bookingReference: currentItem.itemBookingRef,
        sellingPrice: parseFloat(sellingPriceComponents[0]),
        supplierPrice: parseFloat(supplierPriceComponents[0]),
        supplierPriceCurrency: supplierPriceComponents[1],
        errorMessage: currentItem.itemError,
        statusId: currentItem.itemStatus,
        id: currentItem.orderItemId,
      }
      this.showEditOrderItemDialog = true
    },
    async saveEditOrderItemData () {
      this.$vs.loading()
      try {
        await this.$http.put(`api/OrderManage/apps/${this.applicationId}/${this.orderId}/orderItems`, this.editableOrderItemData)
        this.$_notifySuccess('Successfully saved order item data');
        this.$emit('onEditOrderItemSuccess')
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  
    },
    close () {
      this.showEditOrderItemDialog=false
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>