var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "vx-row" }, [
        _vm._m(1),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _c("h5", [
            _vm._v(
              _vm._s(
                _vm.itemdata &&
                  _vm.itemdata.Supplier &&
                  _vm.itemdata.Supplier.Name
              )
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          !_vm.isHotlist
            ? _c("div", { staticClass: "vx-row mb-2" }, [
                _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
                  _vm._v("Booking Reference"),
                ]),
                _c(
                  "dd",
                  {
                    staticClass: "vx-col w-3/5",
                    class: {
                      "booking-ref-strike-through":
                        _vm.orderItem.itemStatus == 6,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.orderItem.itemBookingRef == null
                          ? _vm.orderdata.orderStatus
                          : _vm.orderItem.itemBookingRef
                      )
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Supplier Price", value: _vm.supplierPrice },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _c("div", { staticClass: "vx-row mb-2" }, [
            _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
              _vm._v("Rules Applied:"),
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-3/5" },
              _vm._l(_vm.itemdata.Units, function (itemUnits, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _vm._l(itemUnits.Rates, function (itemrates, index2) {
                      return _c("span", {
                        key: index2,
                        domProps: {
                          innerHTML: _vm._s(
                            itemrates.Pricing.RuleIds == undefined
                              ? ""
                              : itemrates.Pricing.RuleIds.join(", ")
                          ),
                        },
                      })
                    }),
                    index + 1 < itemUnits.Rates.length
                      ? _c("span", [_vm._v(", ")])
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
        ]),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reference",
                    value: _vm.orderItem.itemCancelRef,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Selling Price", value: _vm.sellingPrice },
            }),
          ],
          1
        ),
        _vm.orderItem.itemStatus == 6
          ? _c("div", { staticClass: "vx-col w-1/3" }, [_c("div")])
          : _vm._e(),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reason",
                    value: _vm.orderItem.itemCancelReason,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _vm.orderItem.itemDeadline
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Payment/Ticketing Deadline",
                      value: _vm.orderItem.itemDeadline,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.orderItem.itemPayNowPrice
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Pay Now Price",
                      value: _vm.orderItem.itemPayNowPrice,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("ratings-table", {
        attrs: { itemdata: _vm.itemdata, isHotlist: _vm.isHotlist },
      }),
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "vx-col w-1/3" },
            [
              _c("travelify-display-field", {
                attrs: {
                  label: "Supplier Name",
                  value:
                    _vm.itemData &&
                    _vm.itemData.Supplier &&
                    _vm.itemData.Supplier.Name,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "vx-col w-1/3" }, [
            !_vm.isHotlist
              ? _c("div", { staticClass: "vx-row mb-2" }, [
                  _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
                    _vm._v("Booking Reference"),
                  ]),
                  _c(
                    "dd",
                    {
                      staticClass: "vx-col w-3/5",
                      class: {
                        "booking-ref-strike-through":
                          _vm.orderItem.itemStatus == 6,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.orderItem.itemBookingRef == null
                            ? _vm.orderdata.orderStatus
                            : _vm.orderItem.itemBookingRef
                        )
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "vx-col w-1/3" }),
          _c(
            "div",
            { staticClass: "vx-col w-1/3" },
            [
              _c("travelify-display-field", {
                attrs: {
                  label: "Supplier Price",
                  value: _vm.orderItem.itemSupplierPrice,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-1/3" },
            [
              _c("travelify-display-field", {
                attrs: {
                  label: "Selling Price",
                  value: _vm.orderItem.itemSellingPrice,
                },
              }),
            ],
            1
          ),
          _vm._m(3),
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material mb-4 w-100",
                style: { width: "100%" },
                attrs: {
                  components: _vm.cellRendererComponents,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.routeSegments,
                  gridOptions: _vm.gridOptions,
                  domLayout: "autoHeight",
                  rowSelection: "single",
                  pagination: false,
                  suppressPaginationPanel: true,
                },
              }),
            ],
            1
          ),
          _vm.itemData && _vm.itemData.Travellers
            ? _c("div", { staticClass: "vx-col w-full mt-4" }, [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("Passengers:"),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _vm.itemData && _vm.itemData.Travellers
                ? _c("passenger-list", {
                    attrs: { travellers: _vm.itemData.Travellers },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.itemData && _vm.itemData.ExtraGroups
            ? _c("div", { staticClass: "vx-col w-full mt-4" }, [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("Extra Groups:"),
                ]),
              ])
            : _vm._e(),
          _vm.itemData && _vm.itemData.ExtraGroups
            ? _c("extra-groups", {
                attrs: { extraGroups: _vm.itemData.ExtraGroups },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.itemdata.Descriptions
        ? _c("info-items", {
            staticClass: "mt-6",
            attrs: { infoItems: _vm.itemdata.Descriptions },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full" }, [
        _c("h4", { staticClass: "mb-4" }, [_vm._v("Package Holidays")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-1/3" }, [
      _c("h5", [_vm._v("Accommodation")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full mt-4 mb-4" }, [
      _c("h5", [_vm._v("Flight Routes")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full mt-4" }, [
      _c("span", { staticClass: "font-semibold" }, [_vm._v("Routes:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }