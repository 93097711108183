<template>
  <travio-modal v-show="showBackofficeDialog" width='430' height="60" zIndex="99999">
    <h3 slot="header">Send To Backoffice Confirmation</h3>
    <div slot="body">
      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-full">
          <div>Are you sure you want to send to backoffice?</div>
        </div>
      </div>
    </div>
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button :disabled="errors.any()" color="primary" @click="sendToBackoffice" type="filled">Send</vs-button>
      <vs-button class="ml-2" color="danger" @click="close" type="border">Cancel</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'

export default {
  components: {
    TravioModal,
  },
  data () {
    return {
      showBackofficeDialog: false,
    }
  },
  props: {
    applicationId: { required: true},
    orderId: { required: true},
  },
  computed: {
   
  },
  async mounted (){
    await this.launchBackofficeDialog();
  },
  methods: {
    launchBackofficeDialog() {
      this.showBackofficeDialog = true
    },
    async sendToBackoffice() {
      this.$vs.loading()
      try {
        await this.$http.post(`api/OrderManage/apps/${this.applicationId}/${this.orderId}/backOffice`)
        this.$_notifySuccess('Successfully sent to backoffice')
      } catch (error) {
        console.log(error.response)
        this.$_notifyFailureByResponseData(error.response)
      }
      
      this.$vs.loading.close()
      this.$emit('onSuccessSend')
    },
    close () {
      this.$emit('onCancel')
    }
  }
  
}
</script>

<style>
</style>
