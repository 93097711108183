<template>

  <travio-center-container grid-width='w-full'>
    <h2 class="mb-4" style="color:#636363">Quote Viewer</h2>
    <vx-card>
      <div class="flex flex-wrap justify-start mb-4">
        <vs-button @click="onCancel" type="border" class="mr-4 sm:mb-0 mb-2">Back to Quote List</vs-button>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <strong>List Key:</strong>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <span>{{quote.listKey}}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <strong>Name:</strong>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <span>{{quote.name}}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <strong>Customer Id:</strong>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <span>{{quote.customerId}}</span>
        </div>
      </div>

      <h5 class="mt-5 mb-5" style="color:#636363"><strong>Hotlist Items:</strong></h5>
      <vx-card :set="itemdata = JSON.parse(item.itemData)" v-for="(item,index) in this.hotlistItems" class="mb-5" :key="index">
        <flight-order-item v-if="item.productTypeId =='1'" :orderData="quote" :orderItem="item" :isHotlist="true" />
        <accomodation-item v-if="item.productTypeId =='2'" :orderItem="item" :itemdata="itemdata" :orderdata="quote":isHotlist="true" />
        <package-holidays-item v-if="item.productTypeId =='3'" :orderData="quote" :itemdata="itemdata" :orderItem="item" :isHotlist="true" />
        <tickets-attractions-item v-if="item.productTypeId =='4'" :orderData="quote" :itemdata="itemdata" :orderItem="item" :isHotlist="true" />
        <taxis-transfer-item v-if="item.productTypeId =='5'" :orderData="quote" :itemdata="itemdata" :orderItem="item" :isHotlist="true" />
        <airport-extras-item v-if="item.productTypeId =='6'" :orderData="quote" :itemdata="itemdata" :orderItem="item" :isHotlist="true" />
        <insurance-item v-if="item.productTypeId =='7'" :orderData="quote" :orderItem="item" :isHotlist="true" />
        <car-rental-item v-if="item.productTypeId =='8'" :orderData="quote" :itemdata="itemdata" :orderItem="item" :isHotlist="true" />
        <extra-item v-if="item.productTypeId =='15'" :orderData="quote" :itemdata="itemdata" :orderItem="item" :isHotlist="true" />
      </vx-card>

      <!-- <div class="flex flex-wrap justify-end">
        <vs-button class="ml-2" color="primary" @click="onCancel" type="filled">Back</vs-button>      
      </div> -->
    </vx-card>

  </travio-center-container>
</template>

<script>
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

import FlightOrderItem from './components/FlightOrderItem.vue'
import AccomodationItem from './components/AccomodationItem.vue'
import InsuranceItem from './components/InsuranceItem.vue'
import TicketsAttractionsItem from './components/TicketsAttractionsItem.vue'
import CarRentalItem from './components/CarRentalItem.vue'
import PackageHolidaysItem from './components/PackageHolidaysItem.vue'
import TaxisTransferItem from './components/TaxisTransferItem.vue'
import AirportExtrasItem from './components/AirportExtrasItem.vue'
import ExtraItem from "./components/ExtraItem.vue"

export default {
  components: {
    FlightOrderItem,
    AccomodationItem,
    InsuranceItem,
    TicketsAttractionsItem,
    CarRentalItem,
    PackageHolidaysItem,
    TaxisTransferItem,
    AirportExtrasItem,
    ExtraItem,
  },
  props: {
    applicationId: { required: true },
    listId: { required: true },
  },
  data () {
    return {
      quote: {
        listKey: '',
        name: '',
        customerId: null,
        agentId: null,
        status: ''
        
      },
      hotlistItems: [],
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('ignite')) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
    try {
      const response = await this.$http.get(`api/quoteViewer/${this.applicationId}/quote/${this.listId}`)
      this.hotlistItems = [...response.data.hotlistItems]
      let quoteNoHotlist = response.data
      delete quoteNoHotlist.hotlistItems
      this.quote = Object.assign(this.quote, quoteNoHotlist)

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  mounted() {

  },
  methods: {
    onCancel() {
      this.$router.push({
        name: "application-tools-quote-viewer-list",
        params: { applicationId: this.applicationId },
      });
    },
   
  }
}
</script>

<style>

</style>