<template>
  <ag-grid-vue
    ref="agGridTable"
    :components="cellRendererComponents"
    class="ag-theme-material mb-3"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :rowData="deposits"
    :gridOptions="gridOptions"
    domLayout="autoHeight"
    rowSelection="single"
    :pagination="false"
    :suppressPaginationPanel="true"
  ></ag-grid-vue>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
  },
  props: {
    depositData: { type: Object }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
      },
      deposits: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    const allData = JSON.parse(this.depositData.data);
    const initialPayment = { Num: 'Initial Payment', Amount: allData.Amount, DueDate: 'Now', status: allData.status, isRoot: true }
    this.deposits = [initialPayment, ...allData.Breakdown]
    
  },
  beforeMount () {
    this.gridOptions = {};
    const dueDateValueFormatter = (param) => {
      if(param.value === 'Now') return "Now"
      return param.value && format(new Date(param.value) , this.activeUserInfo.dateFormat)
    }

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Installment', field: 'Num', suppressSizeToFit: true, sortable: true, width: 160 },
      { 
        headerName: 'Amount',
        field: 'Amount',
        valueFormatter: params => params.value && params.value.toFixed(2),
        suppressSizeToFit: true, 
        sortable: true,
        width: 160 
      },
      { 
        headerName: 'Due Date', 
        field: 'DueDate',
        width: 250,
        suppressSizeToFit: true,
        valueFormatter: dueDateValueFormatter
      },
      
      { 
        headerName: 'Status',
        valueGetter: params => params.data.isRoot ? this.depositData.status : '-',
        suppressSizeToFit: false,
        sortable: true,
        width: 150 
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
    console.log(this.depositData)
  },
  methods: {

  }
}
</script>

<style>

</style>