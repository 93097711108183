<template>
  <travio-modal v-show="showCancelOrderItemDialog" width='550' height="390" zIndex="99999">
    <h3 slot="header">Cancel Order Item</h3>
    <div slot="body">
      <dl class="text-sm">Order Item Id</dl>
      <dd class="mb-2">{{ orderItemData.orderItemId }}</dd>

      <dl class="text-sm">Type</dl>
      <dd class="mb-2">{{ orderItemData.productName }}</dd>
      
      <dl class="text-sm">Supplier</dl>
      <dd class="mb-2">{{orderItemData.supplierName || 'No supplier information'}}</dd>

      <dl class="text-sm mt-2">Booking Reference</dl>
      <dd class="mb-2">{{orderItemData.itemBookingRef || 'No Booking Reference information'}}</dd>

      <div v-if="policies" class="text-sm">Policy Information</div>
      <div v-for="(policy, index) in policies" :key="index" class="vx-col w-full mt-2">{{policy}}</div>
      <div v-if="policies && policies.length === 0" class="vx-col w-full">No specified cancellation policy.</div>

      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <label class="text-sm">Cancellation Reason</label>
          <vs-textarea 
            name="Cancellation Reason"
            rows="2" 
            v-model="cancellationReason"
            v-validate="'required|max:250'"
            class="w-full mb-0"/>
          <span class="text-danger text-sm">{{ errors.first('Cancellation Reason') }}</span>
        </div>
      </div>
    </div>
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button class="" color="primary" @click="confirmCancel" type="filled">Confirm</vs-button>      
      <vs-button class="ml-2" color="danger" @click="close" type="border">Cancel</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  components: {
    TravioModal,
    vueCustomScrollbar
  },
  data () {
    return {
      cancellationReason: null,
      showCancelOrderItemDialog: false,
      policies: [],
    }
  },
  props: {
    applicationId: { required: true},
    orderId: { required: true},
    orderItemData: { required: true},
  },
  computed: {
   
  },
  async mounted (){
    this.$vs.loading()
    await this.launchCancelOrderItemDialog();
    this.$vs.loading.close() 
  },
  methods: {
    async launchCancelOrderItemDialog() {

      try {
        const response = await this.$http.get(`api/OrderManage/apps/${this.applicationId}/${this.orderId}/orderItems/${this.orderItemData.orderItemId}/costPolicies`)

        if (!response.data.success ) {
          this.$_notifyFailure("<strong>Unable to cancel: </strong>" + response.data.error)
          this.$vs.loading.close()
          this.$emit('onCancel')
          return
        }

        this.policies = (response.data.itemData && response.data.itemData.policies) || []
      }
      catch (error) {
        this.$_notifyFailure(error)
        this.$emit('onCancel')
      }
      this.showCancelOrderItemDialog = true
    },
    async confirmCancel () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      try {
        const response = await this.$http.post(`api/OrderManage/apps/${this.applicationId}/${this.orderId}/orderItems/cancel`,{
          orderItemId: this.orderItemData.orderItemId,
          cancellationReason: this.cancellationReason
        })

        if( response.data.success ) {
          this.$_notifySuccess('Successfully cancelled order item');
          this.$emit('onCancelOrderItemSuccess')
        } else {
          this.$_notifyFailure('Order item cancellation failed. Reason: ' + response.data.error)
        }
        
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  
    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>
.fixed-height-cancel-order-item-body {
    height: calc(var(--vh, 1vh) * 100 - 23.8rem);
    overflow-y: scroll;
    position: relative;
    margin:auto
  }
</style>