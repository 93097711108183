var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: {
                label: "Supplier Name",
                value:
                  _vm.itemdata &&
                  _vm.itemdata.Supplier &&
                  _vm.itemdata.Supplier.Name,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col w-1/3" }),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          !_vm.isHotlist
            ? _c("div", { staticClass: "vx-row mb-2" }, [
                _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
                  _vm._v("Booking Reference"),
                ]),
                _c(
                  "dd",
                  {
                    staticClass: "vx-col w-3/5",
                    class: {
                      "booking-ref-strike-through":
                        _vm.orderItem.itemStatus == 6,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.orderItem.itemBookingRef == null
                          ? _vm.orderdata.orderStatus
                          : _vm.orderItem.itemBookingRef
                      )
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Supplier Price", value: _vm.supplierPrice },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Rules Applied", value: _vm.rulesApplied },
            }),
          ],
          1
        ),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reference",
                    value: _vm.orderItem.itemCancelRef,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Selling Price", value: _vm.sellingPrice },
            }),
          ],
          1
        ),
        _vm.orderItem.itemStatus == 6
          ? _c("div", { staticClass: "vx-col w-1/3" }, [_c("div")])
          : _vm._e(),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reason",
                    value: _vm.orderItem.itemCancelReason,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _vm.orderItem.itemDeadline
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Payment/Ticketing Deadline",
                      value: _vm.orderItem.itemDeadline,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.orderItem.itemPayNowPrice
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Pay Now Price",
                      value: _vm.orderItem.itemPayNowPrice,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "table-grid-item-col-1" }, [
        _c("div", { staticClass: "table-grid-item-col-4 order-list mt-5" }, [
          _c("div", { staticClass: "img-field" }, [
            _c("img", {
              staticClass: "card-img-top",
              attrs: { src: _vm.itemdata.Media[0].Url, alt: "Card image cap" },
            }),
          ]),
          _c("div", { staticClass: "table-grid-item-col-1-pd" }, [
            _vm._m(1),
            _c("div", {
              staticClass: "label-field",
              domProps: {
                innerHTML: _vm._s(
                  new Date(_vm.itemdata.OutPickup.DateTime)
                    .toLocaleDateString("en-GB", {
                      weekday: "long",
                      day: "numeric",
                      month: "short",
                    })
                    .replace(/ /g, " ")
                ),
              },
            }),
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.OutPickup.Address1) },
            }),
            _c("div", {
              staticClass: "text-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.OutPickup.Address2) },
            }),
          ]),
          _c("div", { staticClass: "table-grid-item-col-1-pd" }, [
            _vm._m(2),
            _c("div", {
              staticClass: "label-field",
              domProps: {
                innerHTML: _vm._s(
                  new Date(_vm.itemdata.OutDropoff.DateTime)
                    .toLocaleDateString("en-GB", {
                      weekday: "long",
                      day: "numeric",
                      month: "short",
                    })
                    .replace(/ /g, " ")
                ),
              },
            }),
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.OutDropoff.Address1) },
            }),
            _c("div", {
              staticClass: "text-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.OutDropoff.Address2) },
            }),
          ]),
          _c("div", { staticClass: "table-grid-item-col-1-pd" }, [
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.Vehicle) },
            }),
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.Company) },
            }),
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.JourneyDistance) },
            }),
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.JourneyDuration) },
            }),
          ]),
        ]),
      ]),
      _vm.itemdata.Information
        ? _c("info-items", {
            staticClass: "mt-6",
            attrs: { infoItems: _vm.itemdata.Information },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("h4", { staticClass: "mb-4" }, [_vm._v("Taxis & Transfers")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "label-field" }, [
      _c("h4", [_vm._v("Out Pick up")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "label-field" }, [
      _c("h4", [_vm._v("Out Drop off")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }