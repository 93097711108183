<template>
  <travio-modal v-show="showAddPaymentDialog" width='560' height="330" zIndex="999">
    <h3 slot="header">Add Payment</h3>
    <div slot="body">
      <!-- <vs-alert v-if="addPaymentData.errorMessage" color="danger" active="true">
        Error: {{addPaymentData.errorMessage}}
      </vs-alert> -->
   
      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Amount</span>
        </div>
        <div class="vx-col w-3/4">
          <div class="vx-row">
            <div class="vx-col w-4/5">
              <vs-input type="number" class="w-full" name="Payment Amount" v-model.number="addPaymentData.amount" v-validate="'required|greaterThanZero'" />
              <span class="text-danger text-sm">{{ errors.first('Payment Amount') }}</span>
            </div>
            <div class="vx-col w-1/5 mt-2">
              <div>{{addPaymentData.currencyCode}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Payment Supplier</span>
        </div>
        <div class="vx-col w-3/4">
          <v-select
            v-model="addPaymentData.paymentSupplierId"
            name="Payment Supplier"
            :reduce="(x) => x.code"
            :clearable="true"
            append-to-body
            v-validate="'required'"
            :options="paymentSupplierOptions"
          />
          <span class="text-danger text-sm">{{ errors.first('Payment Supplier') }}</span>
        </div>
      </div>


      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Payment Method</span>
        </div>
        <div class="vx-col w-3/4">
          <v-select
            v-model="addPaymentData.paymentMethodId"
            name="Payment Method"
            :reduce="(x) => x.code"
            v-validate="'required'"
            :clearable="true"
            append-to-body
            :options="paymentMethodOptions"
          />
          <span class="text-danger text-sm">{{ errors.first('Payment Method') }}</span>
        </div>
      </div>
      
      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Payment Reference</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="addPaymentData.paymentReference" v-validate="'max:250'" name="Payment Reference" />
          <span class="text-danger text-sm">{{ errors.first('Payment Reference') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Payment Notes</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-textarea 
            name="Payment Notes"
            rows="2" 
            v-model="addPaymentData.paymentNotes"
            v-validate="'max:500'"
            class="w-full mb-0"/>
          <span class="text-danger text-sm">{{ errors.first('Payment Notes') }}</span>
        </div>
      </div>

      

    </div>
    
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button class="" color="primary" @click="saveOrderPayment" type="filled">Save</vs-button>      
      <vs-button class="ml-2" color="danger" @click="close" type="filled">Cancel</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  data () {
    return {
      showAddPaymentDialog: false,
      addPaymentData: {},
      paymentSupplierOptions: [],
      paymentMethodOptions: [
        { code: 1, label: 'Card' }, 
        { code: 2, label: 'iDEAL' }, 
        { code: 3, label: 'Alipay' }, 
        { code: 4, label: 'Bancontact' }, 
        { code: 5, label: 'EPS' }, 
        { code: 6, label: 'Giropay' }, 
        { code: 7, label: 'Multibanco' }, 
        { code: 8, label: 'SepaDebit' }, 
        { code: 9, label: 'Przelewy24' }, 
        { code: 10, label: 'Sofort' }, 
        { code: 11, label: 'WeChat' }, 
        { code: 12, label: 'GooglePay' }, 
        { code: 13, label: 'ApplePay' }, 
        { code: 14, label: 'Direct Debit' }, 
        { code: 15, label: 'PayPal' }, 
        { code: 16, label: 'Bank Transfer' }, 
        { code: 17, label: 'Cash' }, 
        { code: 18, label: 'Account' }, 
        { code: 19, label: 'Credit Limit' }, 
      ],
    }
  },
  props: {
    applicationId: { required: true }, 
    orderId: { required: true }, 
    orderCurrencyCode: { required: true },
    balanceRemaining: { required: true }
  },
  created() {
    this.$validator.extend(
      'greaterThanZero',{
      getMessage: field =>  field + ' needs to be greater than zero.',
      validate: (value) => {
        // value must be > zero
        if (value > 0 ) return true;
        return false;
      }
    });
  },
  async mounted (){
    await this.loadFormdata();
    this.addPaymentData = {
      amount: this.balanceRemaining,
      currencyCode: this.orderCurrencyCode,
      paymentSupplierId: null,
      paymentMethodId: 1,
      paymentReference: null,
      paymentNotes: null
    }
    this.showAddPaymentDialog = true
  },
  methods: {
    async loadFormdata () {
      this.$vs.loading()
      try {
        const response = await this.$http.get(`api/OrderManage/apps/${this.applicationId}/${this.orderId}/payments/formdata`)
        this.paymentSupplierOptions = response.data.paymentSuppliers
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  
    },
    async saveOrderPayment () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      
      this.$vs.loading()
      try {
        await this.$http.post(`api/OrderManage/apps/${this.applicationId}/${this.orderId}/payments`, this.addPaymentData)
        
        this.$emit('onAddPaymentSuccess')
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  
    },
    close () {
      this.showAddPaymentDialog=false
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>