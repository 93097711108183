<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <h4 class="mb-4">Package Holidays</h4>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-1/3">
        <h5>Accommodation</h5>
      </div>
      <div class="vx-col w-1/3">
        <h5>{{itemdata && itemdata.Supplier && itemdata.Supplier.Name}}</h5>
      </div>
      <div class="vx-col w-1/3">
        <div class="vx-row mb-2"  v-if="!isHotlist">
          <dl class="vx-col w-2/5 font-semibold">Booking Reference</dl>
          <dd class="vx-col w-3/5" :class="{ 'booking-ref-strike-through': orderItem.itemStatus==6}">{{ orderItem.itemBookingRef == null ? orderdata.orderStatus : orderItem.itemBookingRef }}</dd>
        </div>
      </div>
    </div>
    

    <div class="vx-row">
      <div class="vx-col w-1/3">
        <travelify-display-field label="Supplier Price" :value='supplierPrice' />
      </div>
      <div class="vx-col w-1/3">
        <div class="vx-row mb-2">
          <dl class="vx-col w-2/5 font-semibold">Rules Applied:</dl>
          <div class="vx-col w-3/5">
           <div v-for="(itemUnits,index) in itemdata.Units" :key="index">
                <span v-for="(itemrates,index2) in itemUnits.Rates" :key="index2"
                  v-html="itemrates.Pricing.RuleIds==undefined?'':itemrates.Pricing.RuleIds.join(', ')"></span>
                <span v-if="index+1 < itemUnits.Rates.length">, </span>
              </div>
          </div>
        </div>
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <travelify-display-field label="Cancel Reference" :value='orderItem.itemCancelRef' />
      </div>
      
      <div class="vx-col w-1/3">
        <travelify-display-field label="Selling Price" :value='sellingPrice' />
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <div></div>
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <travelify-display-field label="Cancel Reason" :value='orderItem.itemCancelReason' />
      </div>

      <div class="vx-col w-1/3">
        <div v-if="orderItem.itemDeadline" class="vx-col w-full">
          <travelify-display-field label="Payment/Ticketing Deadline" :value='orderItem.itemDeadline' />
        </div>
        <div v-if="orderItem.itemPayNowPrice" class="vx-col w-full">
          <travelify-display-field label="Pay Now Price" :value='orderItem.itemPayNowPrice' />
        </div>
      </div>

    </div>
    <ratings-table :itemdata="itemdata" :isHotlist="isHotlist" />

    <div class="vx-row">
      <div class="vx-col w-full mt-4 mb-4">
        <h5>Flight Routes</h5>
      </div>
      
      <div class="vx-col w-1/3">
        <travelify-display-field label="Supplier Name" :value='itemData && itemData.Supplier && itemData.Supplier.Name' />
      </div>
      <div class="vx-col w-1/3">
        <div class="vx-row mb-2"  v-if="!isHotlist">
          <dl class="vx-col w-2/5 font-semibold">Booking Reference</dl>
          <dd class="vx-col w-3/5" :class="{ 'booking-ref-strike-through': orderItem.itemStatus==6}">{{ orderItem.itemBookingRef == null ? orderdata.orderStatus : orderItem.itemBookingRef }}</dd>
        </div>
      </div>
      <div class="vx-col w-1/3"></div>

      <div class="vx-col w-1/3">
        <travelify-display-field label="Supplier Price" :value='orderItem.itemSupplierPrice' />
      </div>
      <div class="vx-col w-1/3">
        <travelify-display-field label="Selling Price" :value='orderItem.itemSellingPrice' />
      </div>

      <div class="vx-col w-full mt-4">
        <span class="font-semibold">Routes:</span>
      </div>

      <div class="vx-col w-full">
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material mb-4 w-100"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="routeSegments"
          :gridOptions="gridOptions"
          domLayout="autoHeight"
          rowSelection="single"
          :pagination="false"
          :suppressPaginationPanel="true"
          :style="{ width:'100%' }"
        ></ag-grid-vue>
      </div>

      <div v-if="itemData && itemData.Travellers" class="vx-col w-full mt-4">
        <span class="font-semibold">Passengers:</span>
      </div>
      <div class="vx-col w-full">
        <passenger-list v-if="itemData && itemData.Travellers" :travellers="itemData.Travellers" />
      </div>

      <div v-if="itemData && itemData.ExtraGroups" class="vx-col w-full mt-4">
        <span class="font-semibold">Extra Groups:</span>
      </div>
      <extra-groups v-if="itemData && itemData.ExtraGroups" :extraGroups="itemData.ExtraGroups" />
    </div>

    <info-items v-if="itemdata.Descriptions" class="mt-6" :infoItems="itemdata.Descriptions" />
  </div>

</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import PassengerList from './PassengerList.vue'
import CarrierLogo from '../cell-renderers/CarrierLogo.vue'
import ExtraGroups from './ExtraGroups.vue';
import RatingsTable from './RatingsTable.vue'
import InfoItems from './InfoItems.vue';

export default {
  components: {
    AgGridVue,
    PassengerList,
    TravelifyDisplayField,
    CarrierLogo,
    ExtraGroups,
    RatingsTable,
    InfoItems,
  },
  props: {
    orderItem: { type: Object, required: true },
    itemdata: { type: Object, required: true },
    orderData: { type: Object, required: true }, // whole object from Order Details View
    isHotlist: { type: Boolean, default: false},
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CarrierLogo
      },
      itemData: {},
      routeSegments: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    rulesApplied () {
      return (this.itemData && this.itemData.Pricing && this.itemData.Pricing.RuleIds && 
        this.itemData.Pricing.RuleIds.join(',') ) || 'No Rules'
    },
    sellingPrice () {
      if (!this.isHotlist) {
        return this.orderItem.itemSellingPrice
      } else {
        return this.itemData.Pricing && 
          this.itemData.Pricing.Price + ' ' + this.itemData.Pricing.Currency
      }
    },
    supplierPrice () {
      // itemData is different with itemdata
      if (!this.isHotlist) {
        return this.orderItem.itemSupplierPrice
      } else {
        return this.itemData.Pricing && 
          this.itemData.Pricing.OriginalPrice + ' ' + this.itemData.Pricing.OriginalCurrency
      }
    },
  },
  created () {
    this.itemData = JSON.parse(this.orderItem.itemData)[0]
    
    if(!this.itemData && this.orderItem.itemData){
      // Some this.orderItem.itemData are array while others are object
      this.itemData = JSON.parse(this.orderItem.itemData)
    }
    
    const formatFlightDate = (dateString) => {
      // Handles UTC dates by keeping the exact date but formatting in local format
      const date = new Date(dateString)
      const timeDiff = date.getTimezoneOffset() * 60000;
      const theDate = new Date(date.valueOf() + timeDiff);
      const datePart = theDate.toLocaleDateString('en-GB', { weekday: 'short',day: 'numeric', month: 'short' }).replace(/ /g, ' ') 
      const timePart = theDate.getHours().toLocaleString('en-GB', {minimumIntegerDigits: 2 }) +':' + theDate.getMinutes().toLocaleString('en-GB', {minimumIntegerDigits: 2 })
      return datePart + ' ' + timePart
    }

    this.routeSegments = this.itemData.Routes && this.itemData.Routes.flatMap(x => {
      return x.Segments.map(s => ({
        flightNo: s.FlightNo,
        cabinClass: s.CabinClass,
        originIataCode: s.Origin.IATACode,
        destinationIataCode: s.Destination.IATACode,
        departureTime: formatFlightDate(s.Depart),
        arrivalTime:  formatFlightDate(s.Arrive),
        carrierLogo: 'https://pics.avs.io/120/60/'+ s.MarketingCarrier.Code + '.png',
        direction: x.Direction
      }))
    })
    
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'routeId', hide: true },
      { headerName: 'Flight No', field: 'flightNo', sortable: true, width: 120 },
      { field: 'carrierLogo', hide: true },
      { 
        headerName: 'Depart/Arrive', 
        sortable: true,
        suppressSizeToFit: false,
        width: 160,
        valueGetter: params => params.data.departureTime + " - \n" + params.data.arrivalTime
      },
      { headerName: 'Carrier', suppressSizeToFit: true, width: 160, cellRendererFramework: Vue.extend(CarrierLogo) },
      { headerName: 'Origin', field: 'originIataCode', sortable: true, width: 100 },
      { headerName: 'Destination', field: 'destinationIataCode', sortable: true,  width: 100 },
      { headerName: 'Cabin Class', field: 'cabinClass', sortable: true, width: 150 },
      { headerName: 'Direction', field: 'direction', sortable: true, suppressSizeToFit: false, width: 200 },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  methods: {

  }
}
</script>

<style>
  .table-grid-item {
      display: grid;
      grid-template-columns: 20% 30% 20% 30%;
    }
    .table-grid-item .label-field h4,
    .table-grid-item-col-2 .label-field h4,
    .table-grid-item-col-5 .label-field h4,
    .table-grid-item-col-1 .label-field h4,
    .table-grid-item-col-1 .label-field,
    .table-grid-item-col-3 .label-field h4  {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
    }
    .table-grid-item .text-field span,
    .table-grid-item-col-2 .text-field span,
    .table-grid-item-col-5 .text-field span{
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      word-break: break-word;
    }
    .table-grid-item-col-1{
      display: grid;
      grid-template-columns: auto;
      margin-bottom:10px;
    }
    .table-grid-item-col-1:last-child{
      margin:0;
    }
    .table-grid-item-col-1-pd{
      display: grid;
      grid-template-columns: auto;    
      padding-left: 25px;
    }
    .table-grid-item-col-2{
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .label-field .table-grid-item-col-2 {
      display: grid;
      grid-template-columns: 40% 60%;
    }
    .table-grid-item-col-3{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    .table-grid-item-col-4.order-list{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
    .table-grid-item-col-2.order-list{
      display: grid;
      grid-template-columns: 1fr 2fr;
      border: 1px solid #d7d7dd;
      padding: 10px;
    }
    .custom-table-section{
      border: 1px solid #d7d7dd;
      padding: 10px;
    }
    .table-grid-item-col-5{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 15px;
    }
    .text-field.multi-line {
      display: grid;
      grid-template-columns: auto;
    }
    .img-field img{
      width:100%;
      height:100%;
      border-radius: 5px;
    }  
    .order-details .ag-grid-table{
      height:320px;
    }
    .table-grid-item-col-2-pd {
      padding-left: 20px;
    }
    .img-field img {
      width: 180px;
      height: auto;
      margin: 0 auto;
  }
  </style>
