var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Group Type", value: _vm.itemdata.Type },
            }),
            _c("travelify-display-field", {
              attrs: { label: "Group Name", value: _vm.itemdata.Name },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col w-2/3" }, [
          _c("h6", [_vm._v("Description:")]),
          _c("p", {
            staticClass: "mt-2",
            domProps: { innerHTML: _vm._s(_vm.itemdata.Description) },
          }),
        ]),
      ]),
      _c("h5", { staticClass: "mt-5" }, [_vm._v("Extras:")]),
      _vm._l(_vm.itemdata.Extras, function (extra, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("vs-divider", { staticClass: "mb-3 mt-2" }),
            _c("extra-item-extras", { attrs: { extra: extra } }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row mb-3" }, [
      _c("div", { staticClass: "vx-col w-1/3" }, [_c("h5", [_vm._v("Extra")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }