<template>
  <ag-grid-vue
    ref="agGridTable"
    :components="cellRendererComponents"
    class="ag-theme-material mb-3"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :rowData="participants"
    :gridOptions="gridOptions"
    domLayout="autoHeight"
    rowSelection="single"
    :pagination="false"
    :suppressPaginationPanel="true"
  ></ag-grid-vue>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
  },
  props: {
    participants: { type: Array, required: true }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
      },
      passengers: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {

  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Type', field: 'Type', width: 150, suppressSizeToFit: true },
      { headerName: 'Gender', field: 'Gender', suppressSizeToFit: true, sortable: true, width: 350 },
      { 
        headerName: 'Date Of Birth', 
        field: 'DateOfBirth',
        width: 250,
        suppressSizeToFit: false,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateFormat)
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  methods: {

  }
}
</script>

<style>

</style>
