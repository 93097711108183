<template>
  <travio-modal v-show="showSendEmailDialog" width='430' height="140" zIndex="99999">
    <h3 slot="header">Send Email Confirmation</h3>
    <div slot="body">
      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-full">
          <label class="text-sm mb-2">Email Address(es) - <span> (use comma to separate multiple emails)</span></label>
          <vs-textarea 
            name="Email Address"
            rows="2" 
            v-model="confirmationEmailAddress"
            v-validate="'required|mustBeEmailList|noNewLine'"
            class="w-full mb-0"/>
          <span class="text-danger text-sm">{{ errors.first('Email Address') }}</span>
        </div>
      </div>
    </div>
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button :disabled="errors.any()" color="primary" @click="sendEmailConfirmation" type="filled">Send Email</vs-button>
      <vs-button class="ml-2" color="danger" @click="close" type="border">Cancel</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import { Validator } from 'vee-validate';

Validator.extend('mustBeEmailList', {
  getMessage: field => field + ' must be an email or emails separated by comma.',
  validate: value => {
    // https://stackoverflow.com/questions/49680496/vuelidate-validation-for-comma-seperated-list-of-multiple-emails
    return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,8})[\W]*$/.test(value)
  }
});

Validator.extend('noNewLine', {
  getMessage: field => field + ' must not include new line.',
  validate: value => {
    // https://stackoverflow.com/questions/49680496/vuelidate-validation-for-comma-seperated-list-of-multiple-emails
    return !(/\n/.test(value))
  }
});

export default {
  components: {
    TravioModal,
  },
  data () {
    return {
      emailAddresses: [],
      confirmationEmailAddress: null,
      confirmationEmailAddresses: [],
      showSendEmailDialog: false,
    }
  },
  props: {
    applicationId: { required: true},
    orderId: { required: true},
    customerEmail: { required: true },
  },
  computed: {
   
  },
  async mounted (){
    await this.launchEmailDialog();
  },
  methods: {
    launchEmailDialog() {
      this.showSendEmailDialog = true
      this.confirmationEmailAddress = this.customerEmail
      this.confirmationEmailAddresses.push(this.customerEmail)
    },
    async sendEmailConfirmation() {
      this.$vs.loading()

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      const emails = this.confirmationEmailAddress
        .split(',')
        .map(e => e.trim()) // remove leading and trailing spaces
        .filter(e => e) // remove empty items if user enters comma at the end

      try {
        await this.$http.post(`api/OrderManage/apps/${this.applicationId}/emailConfirmation`, { 
          orderId: this.orderId,
          emailAddresses: emails
        })
        this.$_notifySuccess('Successfully sent email confirmation')
        this.$emit('onSuccessSend')
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    close () {
      this.$emit('onCancel')
    }
  }
  
}
</script>

<style>
</style>
