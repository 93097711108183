<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/3">
        <travelify-display-field label="Extra Type" :value='extra.Type' />
        <travelify-display-field label="Extra Name" :value='extra.Name' />
        <travelify-display-field label="Extra Quantity Selected" :value='extra.QtySelected' />
        <travelify-display-field label="Extra Price" :value='extraPrice' />
      </div>
      <div class="vx-col w-2/3">
        <h6>Description:</h6>
        <p class="mt-2" v-html="extra.Description"></p>
      </div>
    
    </div>

    <div v-if="extra && extra.Participants" class="vx-col w-full mt-4">
      <span class="font-semibold">Participants:</span>
    </div>
    <div v-if="extra && extra.Participants" class="vx-col w-full">
      <extra-participant-list :participants="extra.Participants" />
    </div>
  </div>
</template>

<script>

import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import ExtraParticipantList from './ExtraParticipantList.vue'

export default {
  components: {
    TravelifyDisplayField,
    ExtraParticipantList,
  },
  props: {
    extra: { type: Object, required: true },
  },
  computed: {
    extraPrice () {
      return (this.extra && this.extra.Pricing && `${this.extra.Pricing.Price} ${this.extra.Pricing.Currency}`) || ''
    } 
  },
  
};
</script>

<style>

</style>