<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon title="Edit" icon="Edit2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
    <feather-icon v-if="params.data.itemStatus==4" title="Cancel" icon="XCircleIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onCancelOrderItem" />
  </div>
</template>

<script>

export default {
  methods: {
    onEdit() {
      this.params.context.componentParent.launchOrderItemEditor(this.params.data.orderItemId)
    },
    onCancelOrderItem ()
    {
      if(this.params.data.itemStatus != 4) {
        return
      }
      this.params.context.componentParent.launchCancelOrderItemDialog(this.params.data)  
    }
  },
};
</script>
