<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-1/3">
        <h5>Accommodation</h5>
      </div>
      <div class="vx-col w-1/3">
        <h5>{{supplierSubSupplier}}</h5>
      </div>
      <div class="vx-col w-1/3 ">

        <div class="vx-row mb-2"  v-if="!isHotlist">
          <dl class="vx-col w-2/5 font-semibold">Booking Reference</dl>
          <dd class="vx-col w-3/5" :class="{ 'booking-ref-strike-through': orderItem.itemStatus==6}">{{ orderItem.itemBookingRef == null ? orderdata.orderStatus : orderItem.itemBookingRef }}</dd>
        </div>

      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-1/3">
        <travelify-display-field label="Supplier Price" :value='supplierPrice' />
      </div>
      <div class="vx-col w-1/3">
        <div class="vx-row mb-2">
          <dl class="vx-col w-2/5 font-semibold">Rules Applied:</dl>
          <div class="vx-col w-3/5">
           <div v-for="(itemUnits,index) in itemdata.Units" :key="index">
                <span v-for="(itemrates,index2) in itemUnits.Rates" :key="index2"
                  v-html="itemrates.Pricing.RuleIds==undefined?'':itemrates.Pricing.RuleIds.join(', ')"></span>
                <span v-if="index+1 < itemUnits.Rates.length">, </span>
              </div>
          </div>
        </div>
      </div>
      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <travelify-display-field label="Cancel Reference" :value='orderItem.itemCancelRef' />
      </div>
      <div class="vx-col w-1/3">
        <travelify-display-field label="Selling Price" :value='sellingPrice' />
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <div></div>
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <travelify-display-field label="Cancel Reason" :value='orderItem.itemCancelReason' />
      </div>

      <div class="vx-col w-1/3">
        <div v-if="orderItem.itemDeadline" class="vx-col w-full">
          <travelify-display-field label="Payment/Ticketing Deadline" :value='orderItem.itemDeadline' />
        </div>
        <div v-if="orderItem.itemPayNowPrice" class="vx-col w-full">
          <travelify-display-field label="Pay Now Price" :value='orderItem.itemPayNowPrice' />
        </div>
      </div>
    
    </div>
    <ratings-table :itemdata="itemdata" />
    <info-items v-if="itemdata.Descriptions" class="mt-6" :infoItems="itemdata.Descriptions" />
  </div>
</template>

<script>

import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import RatingsTable from './RatingsTable.vue'
import InfoItems from './InfoItems.vue';

export default {
  components: {
    TravelifyDisplayField,
    RatingsTable,
    InfoItems
  },
  props: {
    orderItem: { type: Object, required: true },
    itemdata: { type: Object, required: true },
    orderdata: { type: Object, required: true },
    isHotlist: { type: Boolean, default: false}
  },
  computed: {
    rulesApplied () {
      return 
    },
    sellingPrice () {
      if (!this.isHotlist) {
        return this.orderItem.itemSellingPrice
      } else {
        return this.itemdata.Pricing && 
          this.itemdata.Pricing.Price + ' ' + this.itemdata.Pricing.Currency
      }
    },
    supplierSubSupplier () {
      var supplier = this.itemData && this.itemData.Supplier && this.itemData.Supplier.Name
      var subSupplier = this.itemData && this.itemData.Supplier && this.itemData.Supplier.SubSupplier
      var subSupplierWithDelimiter = subSupplier ? ` (${subSupplier})` : ''

      return (supplier && subSupplierWithDelimiter) 
        ? supplier + subSupplierWithDelimiter
        : null
    },
    supplierPrice () {
      if (!this.isHotlist) {
        return this.orderItem.itemSupplierPrice
      } else {
        return this.itemdata.Pricing && 
          this.itemdata.Pricing.OriginalPrice + ' ' + this.itemdata.Pricing.OriginalCurrency
      }
    },
  }
};
</script>

<style>
  .booking-ref-strike-through {
    text-decoration: line-through; 
  }
</style>