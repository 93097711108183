var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Extra Type", value: _vm.extra.Type },
          }),
          _c("travelify-display-field", {
            attrs: { label: "Extra Name", value: _vm.extra.Name },
          }),
          _c("travelify-display-field", {
            attrs: {
              label: "Extra Quantity Selected",
              value: _vm.extra.QtySelected,
            },
          }),
          _c("travelify-display-field", {
            attrs: { label: "Extra Price", value: _vm.extraPrice },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-2/3" }, [
        _c("h6", [_vm._v("Description:")]),
        _c("p", {
          staticClass: "mt-2",
          domProps: { innerHTML: _vm._s(_vm.extra.Description) },
        }),
      ]),
    ]),
    _vm.extra && _vm.extra.Participants
      ? _c("div", { staticClass: "vx-col w-full mt-4" }, [
          _c("span", { staticClass: "font-semibold" }, [
            _vm._v("Participants:"),
          ]),
        ])
      : _vm._e(),
    _vm.extra && _vm.extra.Participants
      ? _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("extra-participant-list", {
              attrs: { participants: _vm.extra.Participants },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }