<template>
  <travio-modal v-show="showEditOrderDialog" width='650' height="620" zIndex="999">
    <h3 slot="header">Edit Order Data</h3>
    <div slot="body">
      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Title</span>
        </div>
        <div class="vx-col w-3/4">
            <v-select
              v-model="editableOrderData.customerTitle"
              :reduce="(x) => x.code"
              :clearable="false"
              append-to-body
              :options="titleOptions"
            />
        </div>
      </div>
      
      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Customer Name</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerFirstName" v-validate="'max:50'" name="Customer First Name" />
          <span class="text-danger text-sm">{{ errors.first('Customer First Name') }}</span>
        </div>
      </div>
        <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Customer Last Name</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerLastName" v-validate="'max:50'" name="Customer Last Name" />
          <span class="text-danger text-sm">{{ errors.first('Customer Last Name') }}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Customer Email</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerEmail"  v-validate="'max:250'" name="Customer Email" />
          <span class="text-danger text-sm">{{ errors.first('Customer Email') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Telephone Type</span>
        </div>
        <div class="vx-col w-3/4">
            <v-select
              v-model="editableOrderData.customerTelephoneType"
              name="Telephone Type"
              :reduce="(x) => x.code"
              :clearable="false"
              append-to-body
              :options="phoneTypeOptions"
            />
          <span class="text-danger text-sm">{{ errors.first('Telephone Type') }}</span>
        </div>
      </div>
      
      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Customer Telephone</span>
        </div>
        <div class="vx-col w-3/4 pt-1">
          <div class="vx-row">
            <div class="vx-col pr-1 w-1/5">
              <vs-input class="w-full" placeholder="Prefix" v-model="editableOrderData.customerTelephonePrefix" name="Customer Telephone Prefix" />
              <span class="text-danger text-sm">{{ errors.first('Customer Telephone Prefix') }}</span>
            </div>
            <div class="vx-col pl-1 w-4/5">
              <vs-input class="w-full" placeholder="Telephone Number" v-model="editableOrderData.customerTelephoneNumber" name="Customer Telephone Number" />
              <span class="text-danger text-sm">{{ errors.first('Customer Telephone Number') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Address Line 1</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerAddressLine1" v-validate="'max:250'" name="Address Line 1" />
          <span class="text-danger text-sm">{{ errors.first('Address Line 1') }}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Address Line 2</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerAddressLine2" v-validate="'max:250'" name="Address Line 2" />
          <span class="text-danger text-sm">{{ errors.first('Address Line 2') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>City</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerCity" v-validate="'max:150'" name="Customer City" />
          <span class="text-danger text-sm">{{ errors.first('Customer City') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>State</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerState" v-validate="'max:150'" name="Customer State" />
          <span class="text-danger text-sm">{{ errors.first('Customer State') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Postal Code</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.customerPostalCode" v-validate="'max:50'" name="Customer Postal Code" />
          <span class="text-danger text-sm">{{ errors.first('Customer Postal Code') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/4 pt-1">
          <span>Country</span>
        </div>
        <div class="vx-col w-3/4">
            <v-select
              v-model="editableOrderData.customerCountryCode"
              name="Country Code"
              :reduce="(x) => x.code"
              :clearable="false"
              append-to-body
              :options="countryOptions"
            />
          <span class="text-danger text-sm">{{ errors.first('Country Code') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2 mt-4">
        <div class="vx-col w-1/4 pt-1">
          <span>Internal Reference</span>
        </div>
        <div class="vx-col w-3/4">
          <vs-input class="w-full" v-model="editableOrderData.orderReference" v-validate="'max:50'" name="Customer First Name" />
          <span class="text-danger text-sm">{{ errors.first('Customer First Name') }}</span>
        </div>
      </div>        
      
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button class="" color="primary" @click="saveEditOrderData" type="filled">Save</vs-button>      
      <vs-button class="ml-2" color="danger" @click="close" type="filled">Cancel</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  data () {
    return {
      editableOrderData: {},
      showEditOrderDialog: false,
      countryOptions: [],
    }
  },
  props: {
    applicationId: { required: true},
    orderId: { required: true},
    ordersList: { type: Object, required: true }
  },
  computed: {
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    phoneTypeOptions () {
      return this.$store.state.lookups.phoneTypeOptions
    },
  },
  async mounted (){
    await this.launchEditOrderDialog();
  },
  methods: {
    async launchEditOrderDialog() {

      this.$vs.loading()
      try {
        const formDataResponse = await this.$http.get(`api/OrderManage/apps/${this.applicationId}/${this.orderId}/formdata`)
        this.countryOptions = formDataResponse.data.countryOptions;
      }
      catch (error) {
        this.$_notifyFailure(error)
      }

      this.$vs.loading.close()

      const orderCustomer = JSON.parse(JSON.stringify(this.ordersList.orderCustomer))
      const customerAddress = orderCustomer.customerAddress
      const customerTelephone = orderCustomer.customerTel
      
      this.editableOrderData = {
        orderReference: this.ordersList.orderRef,
        customerFirstName: orderCustomer.customerFirstName,
        customerLastName: orderCustomer.customerLastName,
        customerTitle: orderCustomer.customerTitle,
        customerEmail: orderCustomer.customerEmail,
        customerTelephoneType: customerTelephone && customerTelephone.telephoneTypeId,
        customerTelephonePrefix: customerTelephone && customerTelephone.telephonePrefix,
        customerTelephoneNumber: customerTelephone && customerTelephone.telephoneNumber,
        
        customerAddressLine1: customerAddress && customerAddress.addressLine1,
        customerAddressLine2: customerAddress && customerAddress.addressLine2,
        customerCountryCode: customerAddress && customerAddress.addressCountryCode,
        customerPostalCode: customerAddress && customerAddress.addressPostalCode,
        customerState: customerAddress && customerAddress.addressState,
        customerCity: customerAddress && customerAddress.addressCity,
      }
      this.showEditOrderDialog = true
    },
    async saveEditOrderData () {
      this.$vs.loading()
      try {
        await this.$http.put(`api/OrderManage/apps/${this.applicationId}/${this.orderId}`, this.editableOrderData)
        this.$_notifySuccess('Successfully saved order data');
        this.$emit('onSaveSuccess')
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  
    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>