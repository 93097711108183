<template>
  <div>
    <feather-icon :title="params.data.paymentNotes || ''" icon="InfoIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
  </div>
</template>

<script>
export default {

}
</script>
