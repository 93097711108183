<template>
  <div>
    <div class="table-grid-item-col-3">
      <div class="label-field">
        <h4>Car Rental</h4>
        <div class="table-grid-item-col-2">
          <div class="label-field">
            <h4>Supplier Price:</h4>
          </div>
          <div class="text-field">
            <span v-html="supplierPrice"></span>
          </div>
        </div>
      </div>
      <div class="label-field">
        <h4 v-html="itemdata.Supplier && itemdata.Supplier.Name"></h4>
        <div class="table-grid-item-col-2">
          <div class="label-field">
            <h4>Rules Applied:</h4>
          </div>
          <div class="text-field">
            <span
              v-for="(itemPackage, index) in itemdata.Packages"
              :key="index"
              v-html="
                itemPackage.Pricing.RuleIds == undefined
                  ? ''
                  : itemPackage.Pricing.RuleIds.join(', ')
              "
            ></span>
          </div>
        </div>
      </div>
      <div class="label-field">
        <div class="table-grid-item-col-2" v-if="!isHotlist">
          <div class="label-field">
            <h4>Booking Reference:</h4>
          </div>
          <div class="text-field">
            <h4
              :class="{
                'order-item-strike-through': orderItem.itemStatus == 6,
              }"
              v-html="
                orderItem.itemBookingRef == null
                  ? orderdata.orderStatus
                  : orderItem.itemBookingRef
              "
            ></h4>
          </div>
        </div>
        <div class="table-grid-item-col-2" v-if="orderItem.itemStatus == 6">
          <div class="label-field">
            <h4>Cancel Reference:</h4>
          </div>
          <div class="text-field">
            <h4 v-html="orderItem.itemCancelRef"></h4>
          </div>
        </div>
        <div class="table-grid-item-col-2" v-if="orderItem.itemStatus == 6">
          <div class="label-field">
            <h4>Cancel Reason:</h4>
          </div>
          <div class="text-field">
            <span v-html="orderItem.itemCancelReason"></span>
          </div>
        </div>
        <div class="table-grid-item-col-2">
          <div class="label-field">
            <h4>Selling Price:</h4>
          </div>
          <div class="text-field">
            <span v-html="sellingPrice"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-grid-item-col-1">
      <div class="table-grid-item-col-4 order-list mt-5">
        <div class="img-field">
          <img
            class="card-img-top"
            :src="itemdata.Media[0].Url"
            alt="Card image cap"
          />
        </div>
        <div class="table-grid-item-col-1-pd">
          <div class="label-field">
            <h4>Pick up</h4>
          </div>
          <div
            class="label-field"
            v-html="
              new Date(itemdata.Pickup.DateTime)
                .toLocaleDateString('en-GB', {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'short',
                })
                .replace(/ /g, ' ')
            "
          ></div>
          <div class="label-field" v-html="itemdata.Pickup.Address1"></div>
          <div class="text-field" v-html="itemdata.Pickup.Address2"></div>
          <div class="text-field" v-html="itemdata.Pickup.PostalCode"></div>
        </div>
        <div class="table-grid-item-col-1-pd">
          <div class="label-field">
            <h4>Drop off</h4>
          </div>
          <div
            class="label-field"
            v-html="
              new Date(itemdata.Dropoff.DateTime)
                .toLocaleDateString('en-GB', {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'short',
                })
                .replace(/ /g, ' ')
            "
          ></div>
          <div class="label-field" v-html="itemdata.Dropoff.Address1"></div>
          <div class="text-field" v-html="itemdata.Dropoff.Address2"></div>
          <div class="text-field" v-html="itemdata.Dropoff.PostalCode"></div>
        </div>
        <div class="table-grid-item-col-1-pd">
          <div class="label-field" v-html="itemdata.Name"></div>
          <div class="label-field" v-html="itemdata.ClassName"></div>
          <div class="label-field" v-html="itemdata.Seats + ' Seats'"></div>
        </div>
      </div>
    </div>

    <info-items
      v-if="
        itemdata.Packages &&
        itemdata.Packages[0] &&
        itemdata.Packages[0].Descriptions
      "
      class="mt-6"
      :infoItems="itemdata.Packages[0].Descriptions"
    />
  </div>
</template>

<script>
import InfoItems from "./InfoItems.vue";

export default {
  components: {
    InfoItems,
  },
  props: {
    orderItem: { type: Object, required: true },
    itemdata: { type: Object, required: true },
    orderData: { type: Object, required: true }, // whole object from Order Details View
    isHotlist: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    sellingPrice() {
      if (!this.isHotlist) {
        return this.orderItem.itemSellingPrice;
      } else {
        return (
          this.itemdata.Pricing &&
          this.itemdata.Pricing.Price + " " + this.itemdata.Pricing.Currency
        );
      }
    },
    supplierPrice() {
      if (!this.isHotlist) {
        return this.orderItem.itemSupplierPrice;
      } else {
        return (
          this.itemdata.Pricing &&
          this.itemdata.Pricing.OriginalPrice +
            " " +
            this.itemdata.Pricing.OriginalCurrency
        );
      }
    },
  },
  methods: {},
};
</script>

<style>
.table-grid-item {
  display: grid;
  grid-template-columns: 20% 30% 20% 30%;
}
.table-grid-item .label-field h4,
.table-grid-item-col-2 .label-field h4,
.table-grid-item-col-5 .label-field h4,
.table-grid-item-col-1 .label-field h4,
.table-grid-item-col-1 .label-field,
.table-grid-item-col-3 .label-field h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
.table-grid-item .text-field span,
.table-grid-item-col-2 .text-field span,
.table-grid-item-col-5 .text-field span {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  word-break: break-word;
}
.table-grid-item-col-1 {
  display: grid;
  grid-template-columns: auto;
  margin-bottom: 10px;
}
.table-grid-item-col-1:last-child {
  margin: 0;
}
.table-grid-item-col-1-pd {
  display: grid;
  grid-template-columns: auto;
  padding-left: 25px;
}
.table-grid-item-col-2 {
  display: grid;
  grid-template-columns: 20% 80%;
}
.label-field .table-grid-item-col-2 {
  display: grid;
  grid-template-columns: 40% 60%;
}
.table-grid-item-col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.table-grid-item-col-4.order-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.table-grid-item-col-2.order-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid #d7d7dd;
  padding: 10px;
}
.custom-table-section {
  border: 1px solid #d7d7dd;
  padding: 10px;
}
.table-grid-item-col-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}
.text-field.multi-line {
  display: grid;
  grid-template-columns: auto;
}
.img-field img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.order-details .ag-grid-table {
  height: 320px;
}
.table-grid-item-col-2-pd {
  padding-left: 20px;
}
.img-field img {
  width: 180px;
  height: auto;
  margin: 0 auto;
}
</style>
