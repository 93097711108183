var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "w-full" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v("Quote Viewer"),
      ]),
      _c(
        "vx-card",
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start mb-4" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mr-4 sm:mb-0 mb-2",
                  attrs: { type: "border" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v("Back to Quote List")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("strong", [_vm._v("List Key:")]),
            ]),
            _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
              _c("span", [_vm._v(_vm._s(_vm.quote.listKey))]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("strong", [_vm._v("Name:")]),
            ]),
            _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
              _c("span", [_vm._v(_vm._s(_vm.quote.name))]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("strong", [_vm._v("Customer Id:")]),
            ]),
            _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
              _c("span", [_vm._v(_vm._s(_vm.quote.customerId))]),
            ]),
          ]),
          _c(
            "h5",
            { staticClass: "mt-5 mb-5", staticStyle: { color: "#636363" } },
            [_c("strong", [_vm._v("Hotlist Items:")])]
          ),
          _vm._l(this.hotlistItems, function (item, index) {
            return _c(
              "vx-card",
              {
                key: index,
                staticClass: "mb-5",
                attrs: { set: (_vm.itemdata = JSON.parse(item.itemData)) },
              },
              [
                item.productTypeId == "1"
                  ? _c("flight-order-item", {
                      attrs: {
                        orderData: _vm.quote,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "2"
                  ? _c("accomodation-item", {
                      attrs: {
                        orderItem: item,
                        itemdata: _vm.itemdata,
                        orderdata: _vm.quote,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "3"
                  ? _c("package-holidays-item", {
                      attrs: {
                        orderData: _vm.quote,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "4"
                  ? _c("tickets-attractions-item", {
                      attrs: {
                        orderData: _vm.quote,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "5"
                  ? _c("taxis-transfer-item", {
                      attrs: {
                        orderData: _vm.quote,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "6"
                  ? _c("airport-extras-item", {
                      attrs: {
                        orderData: _vm.quote,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "7"
                  ? _c("insurance-item", {
                      attrs: {
                        orderData: _vm.quote,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "8"
                  ? _c("car-rental-item", {
                      attrs: {
                        orderData: _vm.quote,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
                item.productTypeId == "15"
                  ? _c("extra-item", {
                      attrs: {
                        orderData: _vm.quote,
                        itemdata: _vm.itemdata,
                        orderItem: item,
                        isHotlist: true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }